import React from 'react'

//materia-ui
import { Divider, Grid, Typography } from '@mui/material'

export default function Footer() {
  return (
    <>
      <Divider />
      <Grid container style={{ marginTop: '20px' }}>
        <Grid item xs={1} />
        <Grid item xs={5}>
          <Typography variant="body2" color="textSecondary" align="left">
            Copyright © 2024, Summa Labs.
          </Typography>
        </Grid>
        <Grid item xs={5}>
          <Typography variant="body2" color="textSecondary" align="right">
            {process.env.REACT_APP_TARGET === 'development' ? 'STAGE' : ''}{' '}
            {process.env.REACT_APP_VERSION_WEB} /{' '}
            {process.env.REACT_APP_VERSION_API}
          </Typography>
        </Grid>
        <Grid item xs={1} />
      </Grid>
    </>
  )
}
