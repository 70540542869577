import { React } from 'react'

//material ui
import { Card, CardContent, Typography, Link } from '@mui/material'

import dayjs from 'dayjs'

export const STATES = [
  '@new',
  '@shelf',
  '@subject',
  '@lab',
  '@report',
  '@archive',
]
export const STATE_DEFAULT = 0

export function Protocol(props) {
  let batch = props.batch
  let state = props.state

  return batch.state === STATES[state] ? (
    <Card
      style={{
        margin: '10px auto 10px auto',
      }}
    >
      <CardContent>
        <Typography variant="body1">
          <Link href={'/batch/' + batch.id}>{batch.id}</Link>
        </Typography>
        <Typography variant="caption">
          {dayjs(batch.createdAt).format('DD/MM/YYYY HH:mm')}
          {', '}
          {batch.protocol.title}
          {batch.customer ? ', ' + batch.customer.email : ''}
        </Typography>
      </CardContent>
    </Card>
  ) : (
    <></>
  )
}
