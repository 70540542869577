const theme = {
  palette: {
    mode: 'dark',
    primary: {
      //main: '#596DFB',
      main: '#FFFFFF',
    },
    secondary: {
      //main: '#F1F438',
      main: '#ECFF00',
    },
    border: {
      main: 'rgba(255,255,255,0.57)',
    },
    background: {
      default: '#000000',
      paper: '#191E26',
    },
    text: {
      primary: '#FAFAFA',
      secondary: 'rgba(255,255,255,0.57)',
      disabled: 'rgba(255,255,255,0.4)',
    },
  },
  shape: {
    borderRadius: 0,
  },
  typography: {
    fontFamily: 'Poppins',
  },
}

export default theme
