import { React, useState, useEffect } from 'react'

//material ui
import {
  Button,
  ButtonGroup,
  Box,
  Divider,
  FormControlLabel,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControl,
  RadioGroup,
  Radio,
  Typography,
} from '@mui/material'

export default function LabNewTestS2({ batches, autoallocate, onNext }) {
  return (
    <>
      <Divider />
      <Typography variant="body1" style={{ margin: '20px auto 20px 50px' }}>
        Assign individual, tested samples to plate's wells. Important: 2 first
        columns of the plate are not listed and reserved for controls and
        standards.
      </Typography>
      <Box style={{ margin: '40px 40px 40px 40px' }}>
        <TableRow row={'A'} batches={batches} autoallocate={autoallocate} />
        <TableRow row={'B'} batches={batches} autoallocate={autoallocate} />
        <TableRow row={'C'} batches={batches} autoallocate={autoallocate} />
        <TableRow row={'D'} batches={batches} autoallocate={autoallocate} />
        <TableRow row={'E'} batches={batches} autoallocate={autoallocate} />
        <TableRow row={'F'} batches={batches} autoallocate={autoallocate} />
        <TableRow row={'G'} batches={batches} autoallocate={autoallocate} />
        <TableRow row={'H'} batches={batches} autoallocate={autoallocate} />
      </Box>
      <Divider />
      <Button
        variant="contained"
        color="secondary"
        size="small"
        onClick={onNext}
        style={{ margin: '20px auto 20px 40px' }}
      >
        Next
      </Button>
    </>
  )
}

function TableRow(props) {
  let { row, batches, autoallocate } = props

  return (
    <ButtonGroup variant="outlined" fullWidth>
      <TableSlot
        location={{ row: row, col: 3 }}
        batches={batches}
        autoallocate={autoallocate}
      />
      <TableSlot
        location={{ row: row, col: 4 }}
        batches={batches}
        autoallocate={autoallocate}
      />
      <TableSlot
        location={{ row: row, col: 5 }}
        batches={batches}
        autoallocate={autoallocate}
      />
      <TableSlot
        location={{ row: row, col: 6 }}
        batches={batches}
        autoallocate={autoallocate}
      />
      <TableSlot
        location={{ row: row, col: 7 }}
        batches={batches}
        autoallocate={autoallocate}
      />
      <TableSlot
        location={{ row: row, col: 8 }}
        batches={batches}
        autoallocate={autoallocate}
      />
      <TableSlot
        location={{ row: row, col: 9 }}
        batches={batches}
        autoallocate={autoallocate}
      />
      <TableSlot
        location={{ row: row, col: 10 }}
        batches={batches}
        autoallocate={autoallocate}
      />
      <TableSlot
        location={{ row: row, col: 11 }}
        batches={batches}
        autoallocate={autoallocate}
      />
      <TableSlot
        location={{ row: row, col: 12 }}
        batches={batches}
        autoallocate={autoallocate}
      />
    </ButtonGroup>
  )
}

function TableSlot(props) {
  const [sample, setSample] = useState(null)
  const [open, setOpen] = useState(false)

  let { location, batches, autoallocate, ...rest } = props

  const handleClose = (value) => {
    setOpen(false)

    if (sample) {
      sample.location = null
    }

    if (value) {
      value.location = location
    }

    setSample(value)
  }

  const handleCancel = () => {
    setOpen(false)
  }

  useEffect(() => {
    if (autoallocate) {
      let sampleIndex =
        (location.col - 3) * 8 +
        (location.row.charCodeAt(0) - 'A'.charCodeAt(0))

      let searchIndex = 0

      batches.forEach((batch) => {
        batch.samples.forEach((s) => {
          if (sampleIndex === searchIndex) {
            s.location = location
            setSample(s)
          }
          searchIndex++
        })
      })
    }
  }, [batches, autoallocate, location])

  return (
    <>
      <Button
        style={{ minWidth: 75 }}
        {...rest}
        variant={sample ? 'contained' : 'outlined'}
        color={sample ? 'secondary' : 'primary'}
        onClick={() => {
          setOpen(true)
        }}
      >
        {sample ? sample.short_id : location.row + '_' + location.col}
      </Button>
      <SampleSelection
        batches={batches}
        open={open}
        onClose={handleClose}
        onCancel={handleCancel}
      />
    </>
  )
}

function SampleSelection(props) {
  let { batches, open, onClose, onCancel } = props
  let [batch, setBatch] = useState(0)
  let [sample, setSample] = useState(-1)

  return (
    <Dialog open={open}>
      <DialogTitle>Select sample</DialogTitle>
      <FormControl style={{ marginLeft: 20 }}>
        <RadioGroup
          row
          name="kit"
          value={batch}
          onChange={(event) => {
            setBatch(parseInt(event.target.value, 10))
          }}
        >
          {batches.map((batch, index) => (
            <FormControlLabel
              value={index}
              control={<Radio />}
              label={batch.id}
              key={batch.id}
            />
          ))}
        </RadioGroup>
      </FormControl>
      <FormControl style={{ marginLeft: 20 }}>
        <RadioGroup
          row
          name="sample"
          value={sample}
          onChange={(event) => {
            setSample(parseInt(event.target.value, 10))
          }}
        >
          {batches &&
            batches[batch] &&
            batches[batch].samples.map((sample, index) => (
              <FormControlLabel
                value={index}
                control={<Radio />}
                label={sample.short_id}
                key={sample.short_id}
                style={{ minWidth: 100 }}
                disabled={sample.location !== null}
              />
            ))}
        </RadioGroup>
      </FormControl>
      <DialogActions>
        <Button
          onClick={() => {
            onCancel()
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            onClose(null)
          }}
        >
          Clear
        </Button>
        <Button
          onClick={() => {
            onClose(batches[batch].samples[sample])
          }}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  )
}
