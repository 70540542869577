import { React, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import axios from 'axios'

import Layout from '../components/Layout'

import { Button, Grid, Typography } from '@mui/material'

export default function RedirectWhoop() {
  let [searchParams] = useSearchParams()

  useEffect(() => {
    let code = searchParams.get('code')
    let scope = searchParams.get('scope')
    let state = searchParams.get('state')

    axios
      .post('/connect/whoop', { code, scope, state })
      .then((response) => {
        console.log(response)
      })
      .catch((error) => {
        console.log(error)
      })
  }, [searchParams])

  return (
    <Layout>
      <Typography
        variant="h3"
        color="primary"
        align="center"
        style={{ margin: '40px 10px 20px 10px' }}
      >
        Thank you!
      </Typography>
      <Typography
        variant="subtitle2"
        color="primary"
        align="center"
        style={{ margin: '40px 10px 20px 10px' }}
      >
        Whoop integration has been enabled
      </Typography>
      <Grid container style={{ margin: '40px auto 40px auto' }}>
        <Grid item xs={4} />
        <Grid item xs={4}>
          <Button variant="contained" color="secondary" size="medium" fullWidth>
            <a href="https://summa.bio">Close</a>
          </Button>
        </Grid>
        <Grid item xs={4} />
      </Grid>
    </Layout>
  )
}
