import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'

//material ui
import {
  Card,
  CardContent,
  CardActions,
  TextField,
  Typography,
  Button,
} from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'

import axios from 'axios'

export function BatchActionsNew({ batch_id }) {
  const navigate = useNavigate()

  const handleSaveClick = () => {
    let body = {
      state: '@shelf',
    }

    axios
      .post('/batch/state/' + batch_id, body)
      .then((response) => {
        navigate(0)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  return (
    <Card style={{ margin: '20px auto 20px auto' }}>
      <CardContent>
        <Typography variant="body1" gutterBottom>
          Actions
        </Typography>
        <Typography variant="caption" display="block">
          You may print QR Codes page here for labelling purposes.
        </Typography>
      </CardContent>
      <CardActions>
        <Button
          size="small"
          color="secondary"
          href={'/protocols/print/' + batch_id}
          target="_blank"
        >
          Print QRs
        </Button>
        <Button
          size="small"
          color="secondary"
          href={axios.defaults.baseURL + '/batchzpl/' + batch_id}
        >
          Export ZPL
        </Button>
        <Button size="small" color="secondary" onClick={handleSaveClick}>
          Move to @Shelf
        </Button>
      </CardActions>
    </Card>
  )
}

export function BatchActionsShelf({ batch_id }) {
  const navigate = useNavigate()

  const [customer, setCustomer] = useState(null)
  const [customers, setCustomers] = useState([{ customer }])

  useEffect(() => {
    axios
      .get('/customers')
      .then((response) => {
        setCustomers(response.data)
      })
      .catch((error) => console.log(error))
  }, [])

  const handleSaveClick = () => {
    let body = {
      customer: customer,
    }

    axios
      .post('/batch/customer/' + batch_id, body)
      .then((response) => {
        navigate(0)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  return (
    <Card style={{ margin: '20px auto 20px auto' }}>
      <CardContent>
        <Typography variant="body1" gutterBottom>
          Actions
        </Typography>
        <Typography variant="caption">
          To change status to @subject, select the subject in the field below
          first. Note: assigning subject moves state automatically to @subject
        </Typography>
        <Autocomplete
          value={customer}
          options={customers}
          onChange={(event, newValue) => {
            setCustomer(newValue)
          }}
          getOptionLabel={(option) => {
            let label = ''

            if (option.last_name !== null) label += option.last_name + ' '

            if (option.first_name !== null) label += option.first_name + ' '

            label += option.email

            return label
          }}
          fullWidth
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select subject"
              margin="normal"
              size="small"
            />
          )}
        ></Autocomplete>
      </CardContent>
      <CardActions>
        <Button
          size="small"
          color="secondary"
          disabled={customer === null}
          onClick={handleSaveClick}
        >
          Assign subject
        </Button>
      </CardActions>
    </Card>
  )
}

export function BatchActionsSubject({ batch_id }) {
  const navigate = useNavigate()

  const handleSaveClick = () => {
    let body = {
      state: '@lab',
    }

    axios
      .post('/batch/state/' + batch_id, body)
      .then((response) => {
        navigate(0)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  return (
    <Card style={{ margin: '20px auto 20px auto' }}>
      <CardContent>
        <Typography variant="body1" gutterBottom>
          Actions
        </Typography>
        <Typography variant="caption" display="block">
          Note that moving the Sample Set to @Lab status will prevent subject to
          record any pending sample.
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small" color="secondary" onClick={handleSaveClick}>
          Move to @Lab
        </Button>
      </CardActions>
    </Card>
  )
}

export function BatchActionsLab({ batch_id }) {
  const navigate = useNavigate()

  const handleSaveClick = () => {
    let body = {
      state: '@report',
    }

    axios
      .post('/batch/state/' + batch_id, body)
      .then((response) => {
        navigate(0)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  return (
    <Card style={{ margin: '20px auto 20px auto' }}>
      <CardContent>
        <Typography variant="body1" gutterBottom>
          Actions
        </Typography>
        <Typography variant="caption" display="block">
          Moving state to @Report enables exporting test reports but same time
          prevents any new lab testing against this sample set.
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small" color="secondary" onClick={handleSaveClick}>
          Move to @Report
        </Button>
      </CardActions>
    </Card>
  )
}

export function BatchActionsReport({ batch_id }) {
  const navigate = useNavigate()

  const handleSaveClick = () => {
    let body = {
      state: '@archive',
    }

    axios
      .post('/batch/state/' + batch_id, body)
      .then((response) => {
        navigate(0)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  return (
    <Card style={{ margin: '20px auto 20px auto' }}>
      <CardContent>
        <Typography variant="body1" gutterBottom>
          Actions
        </Typography>
        <Typography variant="caption" display="block"></Typography>
      </CardContent>
      <CardActions>
        <Button
          size="small"
          color="secondary"
          href={axios.defaults.baseURL + '/batchcsv/' + batch_id}
        >
          Export raw_data
        </Button>
        <Button
          size="small"
          color="secondary"
          href={axios.defaults.baseURL + '/batch/reportcsv/' + batch_id}
        >
          Export report_data
        </Button>
        <Button
          size="small"
          color="secondary"
          href={axios.defaults.baseURL + '/batch/reportjson/' + batch_id}
        >
          Export chatgpt_json
        </Button>
        <Button size="small" color="secondary" onClick={handleSaveClick}>
          Move to @Archive
        </Button>
      </CardActions>
    </Card>
  )
}

export function BatchActionsArchive({ batch_id }) {
  const navigate = useNavigate()

  return (
    <Card style={{ margin: '20px auto 20px auto' }}>
      <CardContent>
        <Typography variant="body1" gutterBottom>
          Actions
        </Typography>
        <Typography variant="caption" display="block"></Typography>
      </CardContent>
      <CardActions>
        <Button
          size="small"
          color="secondary"
          href={axios.defaults.baseURL + '/batchcsv/' + batch_id}
        >
          Export raw_data
        </Button>
        <Button
          size="small"
          color="secondary"
          href={axios.defaults.baseURL + '/batch/reportcsv/' + batch_id}
        >
          Export report_data
        </Button>
        <Button
          size="small"
          color="secondary"
          href={axios.defaults.baseURL + '/batch/reportjson/' + batch_id}
        >
          Export chatgpt_json
        </Button>
      </CardActions>
    </Card>
  )
}
