import React from 'react'

//material ui
import { Card, CardContent, TextField, Typography } from '@mui/material'

export default function SampleO(props) {
  const handleChange = (event) => {
    if (event.target.name === 'notes')
      props.setQuestionnaire({
        ...props.questionnaire,
        notes: event.target.value,
      })
  }

  return (
    <Card style={{ margin: '20px 20px 20px 10px' }}>
      <CardContent>
        <Typography
          variant="body1"
          style={{ flex: 1, textAlign: 'left', marginRight: 10 }}
        >
          Additional notes
        </Typography>
        <>
          <TextField
            name="notes"
            rows={5}
            multiline
            fullWidth
            value={props.questionnaire.notes}
            onChange={handleChange}
            style={{ flex: 1, textAlign: 'center', marginTop: 10 }}
          />
        </>
      </CardContent>
    </Card>
  )
}
