import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'

//components
import Layout from '../components/Layout'
import SampleW from '../components/SampleW'
import SampleB from '../components/SampleB'
import SampleA from '../components/SampleA'
import SampleE from '../components/SampleE'
import SampleO from '../components/SampleO'

//material ui
import {
  Button,
  Card,
  CardContent,
  Grid,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material'

import axios from 'axios'
import { Link as LinkRouter } from 'react-router-dom'

export default function Sample() {
  let params = useParams()

  //details variables and defaults
  const [questionnaire, setQuestionnaire] = useState({
    type: 'W',
    nightQuality: 5,
    wakeupTime: '07:30',
    notes: '',
  })

  const [savingStatus, setSavingStatus] = useState('')
  const [batchNumber, setBatchNumber] = useState(null)

  useEffect(() => {
    if (params.sample_id !== undefined) {
      axios
        .get('/sample/' + params.sample_id)
        .then((response) => {
          if (response.data.state === 'new') {
            setSavingStatus('ready')
          } else {
            setSavingStatus('notValid')
          }
        })
        .catch((error) => console.log(error))
    }
  }, [params.sample_id])

  const handleChange = (event) => {
    let sampleType = event.target.value

    switch (sampleType) {
      case 'W':
        setQuestionnaire({
          type: sampleType,
          nightQuality: 5,
          wakeupTime: '07:30',
          notes: '',
        })
        break
      case 'B':
        setQuestionnaire({
          type: sampleType,
          notes: '',
        })
        break
      case 'A':
        setQuestionnaire({
          type: sampleType,
          exertionLevel: 5,
          notes: '',
        })
        break
      case 'E':
        setQuestionnaire({
          type: sampleType,
          dayType: 'Training',
          dayRate: 5,
          totalLoad: 0,
          exerciseType: 'Cardio',
          exerciseDuration: 0,
          averageHR: 0,
          maxHR: 0,
          recoveryRate: 5,
          notes: '',
        })
        break
      case 'O':
        setQuestionnaire({
          type: sampleType,
          notes: '',
        })
        break
      default:
        break
    }
  }

  const handleSaveClick = () => {
    if (savingStatus !== 'ready') return

    let body = {
      questionnaire: questionnaire,
    }

    setSavingStatus('saving')

    axios
      .post('/sample/questionnaire/' + params.sample_id, body)
      .then(function (response) {
        if (response.data.details === 0) {
          setSavingStatus('saved_success_first')
          setBatchNumber(response.data.batch)
        } else setSavingStatus('saved_success')
      })
      .catch(function (error) {
        setSavingStatus('saved_error')
      })
  }

  return (
    <Layout>
      {savingStatus === 'ready' && (
        <Card style={{ margin: '20px 20px 20px 10px' }}>
          <CardContent>
            <FormControl fullWidth variant="standard">
              <InputLabel id="sampling-label">Choose sampling type</InputLabel>
              <Select
                name="SampleType"
                labelId="sampling-label"
                id="sampling-select"
                value={questionnaire.type}
                onChange={handleChange}
              >
                <MenuItem value={'W'}>Wake Up</MenuItem>
                <MenuItem value={'B'}>Before exercise</MenuItem>
                <MenuItem value={'A'}>After excercise</MenuItem>
                <MenuItem value={'E'}>Evening</MenuItem>
                <MenuItem value={'O'}>Other</MenuItem>
              </Select>
            </FormControl>
          </CardContent>
        </Card>
      )}

      {savingStatus === 'ready' && questionnaire.type === 'W' && (
        <SampleW
          questionnaire={questionnaire}
          setQuestionnaire={setQuestionnaire}
        />
      )}

      {savingStatus === 'ready' && questionnaire.type === 'B' && (
        <SampleB
          questionnaire={questionnaire}
          setQuestionnaire={setQuestionnaire}
        />
      )}

      {savingStatus === 'ready' && questionnaire.type === 'A' && (
        <SampleA
          questionnaire={questionnaire}
          setQuestionnaire={setQuestionnaire}
        />
      )}

      {savingStatus === 'ready' && questionnaire.type === 'E' && (
        <SampleE
          questionnaire={questionnaire}
          setQuestionnaire={setQuestionnaire}
        />
      )}

      {savingStatus === 'ready' && questionnaire.type === 'O' && (
        <SampleO
          questionnaire={questionnaire}
          setQuestionnaire={setQuestionnaire}
        />
      )}

      {(savingStatus === 'ready' || savingStatus === 'saving') && (
        <Grid container style={{ margin: '40px auto 40px auto' }}>
          <Grid item xs={2} />
          <Grid item xs={8}>
            <Button
              variant="contained"
              color="secondary"
              size="medium"
              fullWidth
              onClick={handleSaveClick}
            >
              {savingStatus === 'ready' && 'Save'}
              {savingStatus === 'saving' && 'Saving...'}
            </Button>
          </Grid>
          <Grid item xs={2} />
        </Grid>
      )}

      {savingStatus === 'saved_success' && (
        <>
          <Typography
            variant="h3"
            color="primary"
            align="center"
            style={{ margin: '40px 10px 20px 10px' }}
          >
            Thank you!
          </Typography>
          <Typography
            variant="subtitle2"
            color="primary"
            align="center"
            style={{ margin: '40px 10px 20px 10px' }}
          >
            Sample has been already saved.
          </Typography>
          <Grid container style={{ margin: '40px auto 40px auto' }}>
            <Grid item xs={4} />
            <Grid item xs={4}>
              <Button
                variant="contained"
                color="secondary"
                size="medium"
                fullWidth
              >
                <a href="https://summa.bio">Close</a>
              </Button>
            </Grid>
            <Grid item xs={4} />
          </Grid>
        </>
      )}

      {savingStatus === 'notValid' && (
        <>
          <Typography
            variant="h3"
            color="primary"
            align="center"
            style={{ margin: '40px 10px 20px 10px' }}
          >
            Error
          </Typography>
          <Typography
            variant="subtitle2"
            color="primary"
            align="center"
            style={{ margin: '40px 10px 20px 10px' }}
          >
            This code is already scanned.
          </Typography>
          <Grid container style={{ margin: '40px auto 40px auto' }}>
            <Grid item xs={4} />
            <Grid item xs={4}>
              <Button
                variant="contained"
                color="secondary"
                size="medium"
                fullWidth
              >
                <a href="https://summa.bio">Close</a>
              </Button>
            </Grid>
            <Grid item xs={4} />
          </Grid>
        </>
      )}

      {savingStatus === 'saved_success_first' && (
        <>
          <Typography
            variant="h3"
            color="primary"
            align="center"
            style={{ margin: '40px 10px 20px 10px' }}
          >
            Thank you!
          </Typography>
          <Typography
            variant="subtitle2"
            color="primary"
            align="center"
            style={{ margin: '40px 10px 20px 10px' }}
          >
            Answer to few basic questions that will affect the protocol's
            calculation method.
          </Typography>
          <Grid container style={{ margin: '40px auto 40px auto' }}>
            <Grid item xs={2} />
            <Grid item xs={8}>
              <Button
                variant="contained"
                color="secondary"
                size="medium"
                fullWidth
                component={LinkRouter}
                to={'/samplefirst/' + batchNumber}
                key={batchNumber}
              >
                Go to questions
              </Button>
            </Grid>
            <Grid item xs={2} />
          </Grid>
        </>
      )}

      <Typography
        variant="body2"
        color="textSecondary"
        align="center"
        style={{ margin: '40px 10px 20px 10px' }}
      >
        Sample number: {params.sample_id}
      </Typography>
    </Layout>
  )
}
