import { React, useState, useEffect } from 'react'

//utils
import axios from 'axios'

//material ui
import {
  Box,
  Checkbox,
  Divider,
  Grid,
  Typography,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Input,
  FormGroup,
  FormControlLabel,
} from '@mui/material'

import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import ControlPointIcon from '@mui/icons-material/ControlPoint'

export default function LabNewTestS1({ onNext }) {
  const [batches, setBatches] = useState(null)
  const [selectedBatches, setSelectedBatches] = useState([])
  const [totalSamples, setTotalSamples] = useState(0)
  const [autoallocate, setAutoallocate] = useState(true)
  const [dialogStatus, setDialogStatus] = useState({
    show: false,
    cross_index: 0,
    result: { code: false, samples: '' },
  })

  useEffect(() => {
    axios
      .get('/batches')
      .then((response) => {
        let batches = []
        response.data.forEach((doc) => {
          if (doc.state === '@lab') {
            const batch = {}
            batch.id = doc.id
            batch.no_of_samples = doc.protocol.no_of_samples
            batch.short_id_min =
              doc.protocol.short_id_start_at === undefined
                ? 0
                : doc.protocol.short_id_start_at
            batch.short_id_max = batch.short_id_min + batch.no_of_samples - 1
            batch.checked = false
            batches.push(batch)
          }
        })
        setBatches(batches)
      })
      .catch((error) => console.log(error.response.data))
  }, [])

  const calculateTotalSamples = (batches) => {
    let result = 0
    batches.forEach((batch) => {
      result = result + batch.samples.length
    })
    return result
  }

  return (
    <>
      <Divider />
      <Typography variant="body1" style={{ margin: '20px auto 20px 50px' }}>
        {!batches ? 'Loading data, please wait...' : ''}
        {batches && batches.length
          ? 'Please select Sample Set(s) that are being examined during the test.'
          : ''}
        {batches && batches.length === 0
          ? 'There are no Sample Sets marked with @lab state. Creating a test is not possible.'
          : ''}
      </Typography>

      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid item>
          <Typography variant="body1" style={{ marginBottom: '5px' }}>
            Available kits
          </Typography>
          <Box
            sx={{
              width: 500,
              height: 300,
              overflow: 'auto',
              bgcolor: 'background.paper',
            }}
          >
            <List dense>
              {batches &&
                batches.map((batch, index) => (
                  <ListItem key={'left' + batch.id}>
                    <ListItemButton
                      onClick={() => {
                        setDialogStatus({
                          show: true,
                          cross_index: index,
                          result: {
                            code: false,
                            samples:
                              batch.short_id_min + '-' + batch.short_id_max,
                          },
                        })
                      }}
                    >
                      <ListItemIcon>
                        <ControlPointIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          batch.id +
                          ' samples: ' +
                          batch.no_of_samples +
                          ' (' +
                          batch.short_id_min +
                          '-' +
                          batch.short_id_max +
                          ')'
                        }
                      />
                    </ListItemButton>
                  </ListItem>
                ))}
            </List>
          </Box>
        </Grid>
        <Grid item></Grid>
        <Grid item>
          <Typography variant="body1" style={{ marginBottom: '5px' }}>
            Selected kits
          </Typography>
          <Box
            sx={{
              width: 500,
              height: 300,
              overflow: 'auto',
              bgcolor: 'background.paper',
            }}
          >
            <List dense>
              {selectedBatches.map((batch, index) => (
                <ListItem key={'right' + batch.id}>
                  <ListItemButton
                    onClick={() => {
                      if (batches) batches[batch.cross_index].checked = false
                      let newSelectedBatches = [...selectedBatches]
                      newSelectedBatches.splice(index, 1)
                      setSelectedBatches(newSelectedBatches)
                      setTotalSamples(calculateTotalSamples(newSelectedBatches))
                    }}
                  >
                    <ListItemIcon>
                      <HighlightOffIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        batch.id +
                        ' samples: ' +
                        batch.samples.length +
                        ' as ' +
                        batch.samples_raw
                      }
                    />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Box>
        </Grid>
      </Grid>

      <Box style={{ margin: '10px 10px 20px 50px' }}>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={autoallocate}
                onChange={() => {
                  setAutoallocate(!autoallocate)
                }}
                name="Autoallocate"
              />
            }
            label={'Autoallocate samples onto plate'}
          />
        </FormGroup>
        <Typography variant="caption">
          {batches && batches.length
            ? 'Selected number of samples in total: ' + totalSamples
            : ''}
        </Typography>
      </Box>
      <Divider />
      <Button
        variant="contained"
        color="secondary"
        size="small"
        onClick={() => onNext(selectedBatches, autoallocate)}
        style={{ margin: '20px auto 20px 50px' }}
        disabled={totalSamples === 0}
      >
        Next
      </Button>

      <Dialog open={dialogStatus.show}>
        <DialogTitle>Samples selection</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter samples to be analysed for the kit.
          </DialogContentText>
          <DialogContentText>
            Use numbers and ranges separated by space or comma.
          </DialogContentText>
          <DialogContentText>Example: "0-10 13,14 18-27"</DialogContentText>
          <Input
            fullWidth
            onChange={(event) => {
              setDialogStatus({
                ...dialogStatus,
                result: { code: false, samples: event.target.value },
              })
            }}
            style={{ margin: '20px auto 20px auto' }}
            value={dialogStatus.result.samples}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setDialogStatus({ ...dialogStatus, show: false })
            }}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              //dismiss dialog
              batches[dialogStatus.cross_index].checked = true
              setDialogStatus({ ...dialogStatus, show: false })

              //extract samples
              const numbers = []
              for (const [
                ,
                beginStr,
                endStr,
              ] of dialogStatus.result.samples.matchAll(/(\d+)(?:-(\d+))?/g)) {
                const [begin, end] = [beginStr, endStr].map(Number)
                numbers.push(begin)
                if (endStr !== undefined) {
                  for (let num = begin + 1; num <= end; num++) {
                    numbers.push(num)
                  }
                }
              }

              let numbersInRange = []

              //remove items out of range
              numbers.forEach((num, index) => {
                let int = parseInt(num)
                if (
                  int >= batches[dialogStatus.cross_index].short_id_min &&
                  int <= batches[dialogStatus.cross_index].short_id_max
                ) {
                  numbersInRange.push(int)
                }
              })

              //add selected batch to selected batches
              let newSelectedBatches = [...selectedBatches]
              newSelectedBatches.push({
                ...batches[dialogStatus.cross_index],
                cross_index: dialogStatus.cross_index,
                samples_raw: dialogStatus.result.samples,
                samples: numbersInRange,
              })
              setSelectedBatches(newSelectedBatches)

              //update total number of samples
              setTotalSamples(calculateTotalSamples(newSelectedBatches))
            }}
            color="primary"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
