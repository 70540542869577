import { React, useState } from 'react'

//material ui
import {
  Divider,
  Input,
  Grid,
  TextField,
  Button,
  Typography,
} from '@mui/material'

export default function LabNewTestS4({ tests, onNext }) {
  const [fileContent, setFileContent] = useState(null)
  const [fileState, setFileState] = useState('')
  const [comments, setComments] = useState('')

  const readFile = (filename) => {
    const reader = new FileReader()
    reader.addEventListener('load', (event) => {
      const result = event.target.result
      setFileContent(result.replace(/\s+/g, ' '))
      setFileState('File read successfully')
    })
    reader.readAsText(filename)
    setFileState('Readig file...')
  }

  return (
    <>
      <Divider />
      <Grid container>
        <Grid item xs={1} />
        <Grid item xs={10}>
          <Typography variant="body1" style={{ margin: '40px auto 20px auto' }}>
            Select file taken from ELISA device:
          </Typography>
          <Input
            label=""
            type="file"
            onChange={(event) => {
              readFile(event.target.files[0])
            }}
            style={{ margin: '20px auto 10px auto', display: 'block' }}
          />
          <Typography variant="caption">{fileState}</Typography>
          <TextField
            label="Additional comments"
            multiline
            fullWidth
            rows={10}
            onChange={(event) => {
              setComments(event.target.value)
            }}
            style={{ margin: '20px auto 20px auto' }}
            value={comments}
          />
        </Grid>
        <Grid item xs={1} />
      </Grid>
      <Divider />
      <Button
        variant="contained"
        color="secondary"
        size="small"
        onClick={() => {
          onNext(fileContent, comments)
        }}
        disabled={!fileContent}
        style={{ margin: '20px auto 20px 40px' }}
      >
        Next
      </Button>
    </>
  )
}
