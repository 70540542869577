import { React, useEffect, useState } from 'react'

//utils
import axios from 'axios'

import { Link as LinkRouter } from 'react-router-dom'

//material ui
import {
  Button,
  Grid,
  Card,
  CardContent,
  CardActions,
  Typography,
} from '@mui/material'

import dayjs from 'dayjs'

//components
import Layout from '../components/Layout'

function Test(props) {
  const test = props.test
  return (
    <Card
      style={{
        minHeight: '10px',
        padding: '10px',
        margin: '10px auto 10px auto',
      }}
    >
      <CardContent>
        <Typography variant="body1">{test.id}</Typography>
        <Typography variant="caption">
          {' '}
          {dayjs(test.createdAt).format('DD/MM/YYYY HH:mm')}
        </Typography>
      </CardContent>
      <CardActions>
        <Button
          color="secondary"
          size="small"
          href={axios.defaults.baseURL + '/tests/export/' + test.id}
        >
          Export data
        </Button>
      </CardActions>
    </Card>
  )
}

export default function Lab() {
  const [tests, setTests] = useState(null)

  useEffect(() => {
    axios
      .get('/tests')
      .then((response) => {
        setTests(response.data)
      })
      .catch((error) => console.log(error.response.data))
  }, [])

  return (
    <Layout title="Lab">
      <Grid container style={{ margin: '40px auto 40px auto' }}>
        <Grid item xs={2} />
        <Grid item xs={8}>
          {tests === null && (
            <Typography variant="body1" color="textPrimary" align="center">
              Loading data...
            </Typography>
          )}

          {tests && tests.length === 0 && (
            <Typography variant="body1" color="textPrimary" align="center">
              No tests recorded yet.
            </Typography>
          )}

          {tests && tests.map((test) => <Test key={test.id} test={test} />)}

          <Button
            variant="contained"
            color="secondary"
            size="small"
            component={LinkRouter}
            to="/lab/newtest"
            style={{ margin: '40px auto 40px auto' }}
          >
            New test
          </Button>
        </Grid>
        <Grid item xs={2} />
      </Grid>
    </Layout>
  )
}
