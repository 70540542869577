import React from 'react'

//material ui
import { Typography } from '@mui/material'

export default function Page404() {
  return (
    <Typography
      variant="h6"
      color="textPrimary"
      align="center"
      style={{ marginTop: '40px' }}
    >
      Ups... Something went wrong as you see 404 page.
    </Typography>
  )
}
