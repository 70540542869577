import React, { useEffect, useState } from 'react'

import { useParams } from 'react-router'
import QRCode from 'react-qr-code'
import axios from 'axios'

//material-ui
import { Box, Typography } from '@mui/material'

export default function ProtocolsPrint() {
  const [samples, setSamples] = useState(null)

  let params = useParams()

  useEffect(() => {
    axios
      .get('/samples/' + params.batch_id)
      .then((response) => {
        setSamples(response.data)
      })
      .catch((error) => console.log(error))
  }, [params.batch_id])

  return (
    <div>
      <Box
        style={{
          minHeight: '60px',
          padding: '20px',
          margin: '20px 20px 20px 20px',
          background: '#fff',
          borderRadius: '0px',
        }}
      >
        <Box
          style={{
            margin: '20px 20px 20px 20px',
          }}
        >
          <Typography variant="body1" align="left">
            THIS IS BATCH BOX COVER
          </Typography>
        </Box>
        <QRCode
          value={
            process.env.REACT_APP_TARGET === 'production'
              ? process.env.REACT_APP_PROD_LOGIN_LANDING_PAGE +
                '/batch/' +
                params.batch_id
              : process.env.REACT_APP_DEV_LOGIN_LANDING_PAGE +
                '/batch/' +
                params.batch_id
          }
          size={75}
          level="M"
        />
        <Box
          style={{
            margin: '20px 20px 20px 20px',
          }}
        >
          <Typography variant="body1" align="left">
            {params.batch_id}
          </Typography>
        </Box>
      </Box>

      {samples ? (
        samples.map((sample) => (
          <div key={sample.id}>
            <Box
              style={{
                minHeight: '30px',
                padding: '20px',
                margin: '20px 20px 20px 20px',
                background: '#fff',
                borderRadius: '0px',
              }}
            >
              <QRCode
                value={
                  process.env.REACT_APP_TARGET === 'production'
                    ? process.env.REACT_APP_PROD_LOGIN_LANDING_PAGE +
                      '/sample/' +
                      sample.id
                    : process.env.REACT_APP_DEV_LOGIN_LANDING_PAGE +
                      '/sample/' +
                      sample.id
                }
                size={50}
                level="M"
              />
              <Box>
                <Typography variant="caption">
                  {sample.short_id} {sample.id}
                </Typography>
              </Box>
            </Box>
          </div>
        ))
      ) : (
        <Typography
          variant="body1"
          color="textPrimary"
          align="center"
          style={{ marginTop: '40px' }}
        >
          Preparing page...
        </Typography>
      )}
    </div>
  )
}
