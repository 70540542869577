import React from 'react'

//material ui
import {
  Card,
  CardContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Slider,
  TextField,
  Typography,
} from '@mui/material'

export default function SampleE(props) {
  const handleChange = (event) => {
    switch (event.target.name) {
      case 'totalLoad':
        props.setQuestionnaire({
          ...props.questionnaire,
          totalLoad: parseInt(event.target.value, 10),
        })
        break

      case 'averageHR':
      case 'maxHR':
        let value = parseInt(event.target.value, 10)
        value = Math.min(220, value)
        value = Math.max(0, value)

        props.setQuestionnaire({
          ...props.questionnaire,
          [event.target.name]: value,
        })
        break

      default:
        props.setQuestionnaire({
          ...props.questionnaire,
          [event.target.name]: event.target.value,
        })
    }
  }

  return (
    <>
      <Card style={{ margin: '20px 20px 20px 10px' }}>
        <CardContent>
          <Typography
            variant="body1"
            display="inline"
            style={{ flex: 1, textAlign: 'left', marginRight: 10 }}
          >
            Is today supposed to be a training day or a recovery day?
          </Typography>
          <FormControl fullWidth variant="standard">
            <Select
              name="dayType"
              labelId="day-label"
              id="day-select"
              value={props.questionnaire.dayType}
              onChange={handleChange}
            >
              <MenuItem value={'Training'}>Training</MenuItem>
              <MenuItem value={'Recovery'}>Recovery</MenuItem>
            </Select>
          </FormControl>
        </CardContent>
      </Card>

      <Card style={{ margin: '20px 20px 20px 10px' }}>
        <CardContent>
          <Typography
            variant="body1"
            display="inline"
            style={{ flex: 1, textAlign: 'left', marginRight: 10 }}
          >
            On a scale of 1- 10, how would you rate your day?
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            style={{ flex: 1, textAlign: 'left', marginRight: 10 }}
          >
            (1= dismal, 10= blissful)
          </Typography>
          <Slider
            name="dayRate"
            value={props.questionnaire.dayRate}
            onChange={handleChange}
            step={1}
            marks
            min={1}
            max={10}
            valueLabelDisplay="on"
            style={{ felx: 1, marginTop: 50 }}
          />
        </CardContent>
      </Card>

      <Card style={{ margin: '20px 20px 20px 10px' }}>
        <CardContent>
          <Typography
            variant="body1"
            style={{ flex: 1, textAlign: 'left', marginRight: 10 }}
          >
            What was the total training load of the day?
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            style={{ flex: 1, textAlign: 'left', marginRight: 10 }}
          >
            TRIMP, TSS, TL, PTE etc. Leave 0 if not available
          </Typography>
          <>
            <TextField
              name="totalLoad"
              type="number"
              value={props.questionnaire.totalLoad}
              style={{
                flex: 1,
                textAlign: 'center',
                marginTop: 10,
                width: 100,
              }}
              onChange={handleChange}
              inputProps={{
                pattern: '[0-9]*',
              }}
            />
          </>
        </CardContent>
      </Card>

      <Card style={{ margin: '20px 20px 20px 10px' }}>
        <CardContent>
          <FormControl fullWidth variant="standard">
            <InputLabel id="exercise-label">
              What was the type of exercise?
            </InputLabel>
            <Select
              name="exerciseType"
              labelId="exercise-label"
              id="exercise-select"
              value={props.questionnaire.exerciseType}
              onChange={handleChange}
            >
              <MenuItem value={'Cardio'}>Cardio</MenuItem>
              <MenuItem value={'HIIT'}>HIIT</MenuItem>
              <MenuItem value={'Resistance'}>Resistance</MenuItem>
            </Select>
          </FormControl>
        </CardContent>
      </Card>

      <Card style={{ margin: '20px 20px 20px 10px' }}>
        <CardContent>
          <Typography
            variant="body1"
            display="inline"
            style={{ flex: 1, textAlign: 'left', marginRight: 10 }}
          >
            What was duration of your exercise (min)?
          </Typography>
          <Slider
            name="exerciseDuration"
            value={props.questionnaire.exerciseDuration}
            onChange={handleChange}
            step={15}
            marks
            min={0}
            max={180}
            valueLabelDisplay="on"
            style={{ felx: 1, marginTop: 50 }}
          />
        </CardContent>
      </Card>

      <Card style={{ margin: '20px 20px 20px 10px' }}>
        <CardContent>
          <Typography
            variant="body1"
            style={{ flex: 1, textAlign: 'left', marginRight: 10 }}
          >
            What was your average HR during training?
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            style={{ flex: 1, textAlign: 'left', marginRight: 10 }}
          >
            (bpm) Please leave 0, if not available
          </Typography>
          <>
            <TextField
              name="averageHR"
              type="number"
              value={props.questionnaire.averageHR}
              style={{
                flex: 1,
                textAlign: 'center',
                marginTop: 10,
                width: 100,
              }}
              onChange={handleChange}
              inputProps={{
                max: 200,
                min: 0,
                pattern: '[0-9]*',
              }}
            />
          </>
        </CardContent>
      </Card>

      <Card style={{ margin: '20px 20px 20px 10px' }}>
        <CardContent>
          <Typography
            variant="body1"
            style={{ flex: 1, textAlign: 'left', marginRight: 10 }}
          >
            What was your maximum HR during training?
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            style={{ flex: 1, textAlign: 'left', marginRight: 10 }}
          >
            (bpm) Please leave 0, if not available
          </Typography>
          <>
            <TextField
              name="maxHR"
              type="number"
              value={props.questionnaire.maxHR}
              style={{
                flex: 1,
                textAlign: 'center',
                marginTop: 10,
                width: 100,
              }}
              onChange={handleChange}
              inputProps={{
                max: 200,
                min: 0,
                pattern: '[0-9]*',
              }}
            />
          </>
        </CardContent>
      </Card>

      <Card style={{ margin: '20px 20px 20px 10px' }}>
        <CardContent>
          <Typography
            variant="body1"
            display="inline"
            style={{ flex: 1, textAlign: 'left', marginRight: 10 }}
          >
            On a scale of 1-10, how would you rate you recovery from today’s
            exercise?
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            style={{ flex: 1, textAlign: 'left', marginRight: 10 }}
          >
            (1= not recovered, 10= fully recovered)
          </Typography>
          <Slider
            name="recoveryRate"
            value={props.questionnaire.recoveryRate}
            onChange={handleChange}
            step={1}
            marks
            min={1}
            max={10}
            valueLabelDisplay="on"
            style={{ felx: 1, marginTop: 50 }}
          />
        </CardContent>
      </Card>
      <Card style={{ margin: '20px 20px 20px 10px' }}>
        <CardContent>
          <Typography
            variant="body1"
            style={{ flex: 1, textAlign: 'left', marginRight: 10 }}
          >
            Additional notes
          </Typography>
          <>
            <TextField
              name="notes"
              rows={5}
              multiline
              fullWidth
              value={props.questionnaire.notes}
              onChange={handleChange}
              style={{ flex: 1, textAlign: 'center', marginTop: 10 }}
            />
          </>
        </CardContent>
      </Card>
    </>
  )
}
