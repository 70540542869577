//firebase
import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'

const firebaseConfig = {
  apiKey:
    process.env.REACT_APP_TARGET === 'production'
      ? process.env.REACT_APP_PROD_FB_API_KEY
      : process.env.REACT_APP_DEV_FB_API_KEY,
  authDomain:
    process.env.REACT_APP_TARGET === 'production'
      ? process.env.REACT_APP_PROD_AUTH_DOMAIN
      : process.env.REACT_APP_DEV_AUTH_DOMAIN,
  projectId:
    process.env.REACT_APP_TARGET === 'production'
      ? process.env.REACT_APP_PROD_PROJECT_ID
      : process.env.REACT_APP_DEV_PROJECT_ID,
  storageBucket:
    process.env.REACT_APP_TARGET === 'production'
      ? process.env.REACT_APP_PROD_STORAGE_BUCKET
      : process.env.REACT_APP_DEV_STORAGE_BUCKET,
  messagingSenderId:
    process.env.REACT_APP_TARGET === 'production'
      ? process.env.REACT_APP_PROD_MESSAGING_SENDER_ID
      : process.env.REACT_APP_DEV_MESSAGING_SENDER_ID,
  appId:
    process.env.REACT_APP_TARGET === 'production'
      ? process.env.REACT_APP_PROD_APP_ID
      : process.env.REACT_APP_DEV_APP_ID,
  measurementId:
    process.env.REACT_APP_TARGET === 'production'
      ? process.env.REACT_APP_PROD_MEASUREMENT_ID
      : process.env.REACT_APP_DEV_MEASUREMENT_ID,
}

export const app = initializeApp(firebaseConfig)
export const auth = getAuth(app)
