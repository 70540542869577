import React, { useEffect, useState } from 'react'

//material ui
import { Card, CardContent, Typography } from '@mui/material'

import dayjs from 'dayjs'

export default function BatchDetails({ batch }) {
  const [freeSamples, setFreeSamples] = useState(0)
  const [usedSamples, setUsedSamples] = useState(0)

  useEffect(() => {
    let free = 0
    let used = 0

    batch.samples.forEach((sample) => {
      if (sample.state === 'new') free++
      else used++
    })

    setFreeSamples(free)
    setUsedSamples(used)
  }, [batch.samples])

  return (
    <>
      <Card style={{ margin: '20px auto 20px auto' }}>
        <CardContent>
          <Typography variant="body1" gutterBottom>
            Details
          </Typography>
          <Typography variant="caption" style={{ display: 'block' }}>
            {batch.id}
          </Typography>
          <Typography variant="caption" style={{ display: 'block' }}>
            {batch.state}
          </Typography>
          <Typography variant="caption">
            {dayjs(batch.createdAt).format('DD/MM/YYYY HH:mm')}
            {', '}
            {batch.protocol.title}
            {', '}
            {batch.protocol.no_of_samples + ' samples'}
          </Typography>
        </CardContent>
      </Card>

      {batch.customer && (
        <Card style={{ margin: '20px auto 20px auto' }}>
          <CardContent>
            <Typography variant="body1" gutterBottom>
              Subject
            </Typography>
            <Typography variant="caption" style={{ display: 'block' }}>
              {dayjs(batch.customer.createdAt).format('DD/MM/YYYY HH:mm')}
              {', '}
              {batch.customer.first_name} {batch.customer.last_name}
              {', '}
              {batch.customer.email}
            </Typography>
          </CardContent>
        </Card>
      )}

      {batch.questionnaire && (
        <Card style={{ margin: '20px auto 20px auto' }}>
          <CardContent>
            <Typography variant="body1" gutterBottom>
              Quesionnaire
            </Typography>
            <Typography variant="caption" style={{ display: 'block' }}>
              {dayjs(batch.questionnaire.createdAt).format('DD/MM/YYYY HH:mm')}
            </Typography>
            <Typography variant="caption" style={{ display: 'block' }}>
              {'Age: ' + batch.questionnaire.age}
              {', '}
              {'Sex: ' + batch.questionnaire.sex}
              {', '}
              {'Height: ' + batch.questionnaire.height}
              {', '}
              {'Weight: ' + batch.questionnaire.weight}
            </Typography>
            <Typography variant="caption" style={{ display: 'block' }}>
              {'Wake up time: ' + batch.questionnaire.wakeupTime}
              {', '}
              {'Timezone change: ' + batch.questionnaire.timezoneChange}
            </Typography>
            <Typography variant="caption" style={{ display: 'block' }}>
              {'Resting HR: ' + batch.questionnaire.restingHR}
              {', '}
              {'Maximum HR: ' + batch.questionnaire.maximumHR}
            </Typography>
            <Typography variant="caption" style={{ display: 'block' }}>
              {'Mental Stress 1w: ' + batch.questionnaire.mentalStress_1w}
              {', '}
              {'Mental Stress 4w: ' + batch.questionnaire.mentalStress_4w}
              {', '}
              {'Physical Stress 1w: ' + batch.questionnaire.physicalStress_1w}
              {', '}
              {'Physical Stress 4w: ' + batch.questionnaire.physicalStress_4w}
            </Typography>
          </CardContent>
        </Card>
      )}

      {batch.samples && (
        <Card style={{ margin: '20px auto 20px auto' }}>
          <CardContent>
            <Typography variant="body1" gutterBottom>
              Samples
            </Typography>
            <Typography variant="caption" style={{ display: 'block' }}>
              {'Free: ' + freeSamples}
            </Typography>
            <Typography variant="caption" style={{ display: 'block' }}>
              {'Used: ' + usedSamples}
            </Typography>
          </CardContent>
        </Card>
      )}

      {batch.tests && (
        <Card style={{ margin: '20px auto 20px auto' }}>
          <CardContent>
            <Typography variant="body1" gutterBottom>
              Lab
            </Typography>
            {batch.tests.map((test) => (
              <Typography variant="caption" style={{ display: 'block' }}>
                {test.type + ' ' + test.test_id}
              </Typography>
            ))}
          </CardContent>
        </Card>
      )}
    </>
  )
}
