import { React, useState, useEffect } from 'react'
import axios from 'axios'
import { useSearchParams } from 'react-router-dom'

import Layout from '../components/Layout'

import { Button, Grid, Link, Typography } from '@mui/material'

export default function ConnectWhoop() {
  let [searchParams] = useSearchParams()
  let [error, setError] = useState('')
  let [email, setEmail] = useState('')
  let [url, setURL] = useState('')

  useEffect(() => {
    let email = searchParams.get('email')
    setEmail(email)

    axios
      .post('/mlogin/', { email })
      .then((response) => {
        setEmail(email)
        setURL(
          'https://api.prod.whoop.com/oauth/oauth2/auth?' +
            'client_id=d81dcf9e-2d27-4e25-a6ec-9897e12e552e&' +
            'scope=read:recovery read:cycles read:workout read:sleep read:profile read:body_measurement offline&' +
            'redirect_uri=https://app.summa.bio/redirect_whoop&' +
            'response_type=code&' +
            'state=' +
            email
        )
      })
      .catch((error) => {
        console.log(error)
        setError(
          'Unfortunantely we cound not recognise you as existing Summa customer. Please contact us to resolve the issue.'
        )
      })
  }, [email, searchParams])

  return (
    <Layout>
      <Grid container style={{ margin: '40px auto 40px auto' }}>
        <Grid item xs={1} sm={3} md={4} />
        <Grid item xs={10} sm={6} md={4}>
          <Typography
            variant="body1"
            color="textPrimary"
            align="left"
            style={{ margin: '10px auto 20px auto' }}
          >
            Hello <br />
            <br />
            You are about to start connecting your Whoop account with Summa.
          </Typography>
          <Typography
            variant="body1"
            color="textPrimary"
            align="left"
            display="inline"
          >
            By proceeding you acknowledge that you have read and agree to the{' '}
          </Typography>
          <Link
            href="https://www.summa.bio/pages/privacy-policy"
            target="_blank"
            underline="always"
          >
            Privacy Policy
          </Link>
          <Button
            variant="contained"
            color="secondary"
            size="medium"
            fullWidth
            style={{ margin: '40px auto 20px auto' }}
            disabled={error !== ''}
            href={url}
          >
            Connect with Whoop
          </Button>
          <Typography
            variant="body2"
            color="red"
            align="center"
            style={{ margin: '10px auto 20px auto' }}
          >
            {error}
          </Typography>
        </Grid>
        <Grid item xs={1} sm={3} md={4} />
      </Grid>
    </Layout>
  )
}
