import React from 'react'

import { Link as LinkRouter } from 'react-router-dom'

//material ui
import {
  Button,
  Card,
  CardContent,
  CardActions,
  Grid,
  Typography,
} from '@mui/material'

//components
import Layout from '../components/Layout'

export default function Home() {
  return (
    <Layout>
      <Grid container style={{ margin: '40px auto 40px auto' }}>
        <Grid item xs={2} />
        <Grid item xs={8}>
          <Card
            style={{
              minHeight: '30px',
              padding: '20px',
              margin: '20px auto 20px auto',
            }}
          >
            <CardContent>
              <Typography variant="body1">Sample sets</Typography>
              <Typography variant="caption">
                View and manage status of all sample sets.
              </Typography>
            </CardContent>
            <CardActions>
              <Typography>
                <Button size="small" color="secondary" href={'protocols'}>
                  Manage
                </Button>
                <Button
                  size="small"
                  component={LinkRouter}
                  color="secondary"
                  to={'protocols/newbatch'}
                >
                  Generate new set
                </Button>
              </Typography>
            </CardActions>
          </Card>

          <Card
            style={{
              minHeight: '30px',
              padding: '20px',
              margin: '20px auto 20px auto',
            }}
          >
            <CardContent>
              <Typography variant="body1">Laboratory</Typography>
              <Typography variant="caption">
                View and execute laboratory tests.
              </Typography>
            </CardContent>
            <CardActions>
              <Typography>
                <Button
                  component={LinkRouter}
                  size="small"
                  color="secondary"
                  to={'/lab'}
                >
                  Manage
                </Button>
              </Typography>
            </CardActions>
          </Card>

          <Card
            style={{
              minHeight: '30px',
              padding: '20px',
              margin: '20px auto 20px auto',
            }}
          >
            <CardContent>
              <Typography variant="body1">Subjects</Typography>
              <Typography variant="caption">View subjects data</Typography>
            </CardContent>
            <CardActions>
              <Typography>
                <Button
                  component={LinkRouter}
                  size="small"
                  color="secondary"
                  to={'/subjects'}
                >
                  View
                </Button>
              </Typography>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={2} />
      </Grid>
    </Layout>
  )
}
