import React, { useEffect, useState } from 'react'
import axios from 'axios'

//material ui
import {
  Box,
  Grid,
  Tabs,
  Tab,
  Typography,
  Card,
  CardContent,
  Link,
} from '@mui/material'

import {
  DataGridPremium,
  useGridApiRef,
  useKeepGroupedColumnsHidden,
} from '@mui/x-data-grid-premium'

//components
import Layout from '../components/Layout'

const test_data = {
  columns: [
    {
      field: 'name',
      headerName: 'Name',
      width: 200,
    },
    {
      field: 'title',
      headerName: 'Protocol',
      width: 200,
    },
    {
      field: 'state',
      headerName: 'State',
      width: 100,
    },
    {
      field: 'sampling_start',
      headerName: 'Start date',
      width: 200,
      type: 'dateTime',
      valueGetter: (value) => value && new Date(value),
    },
    {
      field: 'sampling_last',
      headerName: 'Last activity',
      width: 200,
      type: 'dateTime',
      valueGetter: (value) => value && new Date(value),
    },
    {
      field: 'no_of_samples',
      headerName: 'Samples',
      width: 100,
    },
    {
      field: 'no_of_samples_used',
      headerName: 'Used',
      width: 100,
    },
    {
      field: 'id',
      headerName: 'Box id',
      width: 250,
      renderCell: (params) => (
        <Typography variant="caption">
          <Link href={'/batch/' + params.value} target="_blank">
            {params.value}
          </Link>
        </Typography>
      ),
    },
  ],
  rows: [],
}

export default function Subjects() {
  const [data, setData] = useState({
    columns: test_data.columns,
    rows: [],
  })
  const apiRef = useGridApiRef()

  const initialState = useKeepGroupedColumnsHidden({
    apiRef,
    initialState: {
      columns: {},
      rowGrouping: {
        model: ['name'],
      },
      sorting: {
        sortModel: [{ field: 'name', sort: 'asc' }],
      },
    },
  })

  useEffect(() => {
    axios
      .get('/batches')
      .then((batches) => {
        let rows = []
        batches.data.forEach((batch) => {
          rows.push({
            id: batch.id,
            name: batch.customer
              ? batch.customer.last_name + ' ' + batch.customer.first_name
              : 'Unallocated',
            state: batch.state,
            ...batch.protocol,
          })
        })
        setData({ columns: data.columns, rows: rows })
      })
      .catch((error) => console.log(error.response.data))
  }, [])

  return (
    <Layout title="Reports">
      <Grid container style={{ margin: '40px auto 40px auto' }}>
        <Grid item xs={1} />
        <Grid item xs={10}>
          <Card
            style={{
              minHeight: '30px',
              padding: '20px',
              margin: '20px auto 20px auto',
            }}
          >
            <CardContent>
              <DataGridPremium
                {...data}
                apiRef={apiRef}
                //groupingColDef={{ leafField: 'protocol' }}
                initialState={initialState}
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={1} />
      </Grid>
    </Layout>
  )
}
