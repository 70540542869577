import { React, useEffect, useState } from 'react'

import Layout from '../components/Layout'

//auth
import { useAuth } from '../contexts/AuthContext'

//mui
import { Typography } from '@mui/material'

export default function Logout() {
  const auth = useAuth()

  const STATE_LOGGING_OUT = 0
  const STATE_LOGOUT = 1
  const STATE_LOGOUT_ERROR = 2

  //title texts for each state
  const title = {}
  title[STATE_LOGGING_OUT] = 'Please wait while you are being logout...'
  title[STATE_LOGOUT] =
    'You have been logout succesfully. It is safe to close the browser window now.'
  title[STATE_LOGOUT_ERROR] = 'Ups.... something got wrong.'

  const [state, setState] = useState()

  useEffect(() => {
    setState(STATE_LOGGING_OUT)
    window.localStorage.removeItem('emailForSignIn')

    auth
      .logout()
      .then(setState(STATE_LOGOUT))
      .catch((error) => {
        setState(STATE_LOGOUT_ERROR)
        console.error(error)
      })
  }, [auth])

  return (
    <Layout>
      <Typography
        variant="subtitle2"
        color="textPrimary"
        style={{
          flex: 1,
          textAlign: 'center',
          margin: '20px 20px 20px 20px',
        }}
      >
        {title[state]}
      </Typography>
    </Layout>
  )
}
