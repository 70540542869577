import React, { useEffect, useState } from 'react'

import Layout from '../components/Layout'

//utils
import axios from 'axios'
import { Link as LinkRouter } from 'react-router-dom'

//mui
import {
  Button,
  Card,
  CardContent,
  Grid,
  FormControl,
  Select,
  MenuItem,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material'

export default function ProtocolsNewBatch() {
  const [protocols, setProtocols] = useState(null)
  const [protocol_id, setProtocolId] = useState(null)
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [confirmationMsg, setConfirmaationMsg] = useState('')

  useEffect(() => {
    axios
      .get('/protocols')
      .then((response) => {
        setProtocols(response.data)
        setProtocolId(response.data[0].id)
      })
      .catch((error) => console.log(error.response.data))
  }, [])

  const generateBatch = () => {
    axios
      .post('/batches', {
        protocol_id: protocol_id,
      })
      .then((response) => {
        setConfirmaationMsg(
          'New batch ' + response.data + ' has been successfully generated',
        )
        setShowConfirmation(true)
      })
      .catch((error) => {
        setConfirmaationMsg(
          'Something went wrong. Error: ' + error.response.data.error,
        )
        setShowConfirmation(true)
      })
  }

  return (
    <Layout title="New Sample Set">
      <Grid container style={{ margin: '40px auto 40px auto' }}>
        <Grid item xs={2} />
        <Grid item xs={8}>
          <Card style={{ margin: '20px 20px 20px 10px' }}>
            <CardContent>
              <Typography
                variant="body1"
                display="inline"
                style={{ flex: 1, textAlign: 'left', marginRight: 10 }}
              >
                Select protocol. Number of samples per set will be taken from
                protocol definition. New protocol can be added by administrator.
              </Typography>
              <FormControl
                style={{ display: 'flex', fullWidth: true, marginTop: 20 }}
              >
                <Select
                  name="SampleType"
                  labelId="sampling-label"
                  id="sampling-select"
                  value={protocol_id}
                  onChange={(e) => {
                    setProtocolId(e.target.value)
                  }}
                >
                  {protocols ? (
                    protocols.map((protocol) => (
                      <MenuItem key={protocol.id} value={protocol.id}>
                        {protocol.title}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem>Loading protocols</MenuItem>
                  )}
                </Select>
              </FormControl>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={2} />
      </Grid>
      <Grid container style={{ margin: '40px auto 40px auto' }}>
        <Grid item xs={2} />
        <Grid item xs={8} style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            variant="contained"
            color="secondary"
            size="small"
            onClick={generateBatch}
          >
            Generate new set
          </Button>
        </Grid>
        <Grid item xs={2} />
      </Grid>

      <Dialog open={showConfirmation}>
        <DialogTitle id="form-dialog-title">New sample set</DialogTitle>
        <DialogContent>
          <DialogContentText>{confirmationMsg}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setShowConfirmation(false)
            }}
            color="primary"
          >
            Back
          </Button>
          <Button component={LinkRouter} to={'/protocols'}>
            See sample sets
          </Button>
        </DialogActions>
      </Dialog>
    </Layout>
  )
}
