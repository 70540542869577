import React, { useState } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router'

//material ui
import {
  Button,
  Card,
  CardContent,
  CardActions,
  Typography,
} from '@mui/material'

export default function BatchAI({ batch }) {
  const navigate = useNavigate()

  const [working, setWorking] = useState(false)

  const handleSaveClick = () => {
    setWorking(true)
    axios
      .post('/batch/aireport/' + batch.id)
      .then((response) => {
        navigate(0)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  return (
    <>
      <Card style={{ margin: '20px auto 20px auto' }}>
        <CardContent>
          <Typography variant="body1" gutterBottom>
            AI experimental
          </Typography>
          <Typography
            variant="caption"
            style={{ display: 'block', whiteSpace: 'pre-line' }}
          >
            {working
              ? 'Working on it, page will refresh automatically'
              : batch.ai_response === undefined
              ? 'Nothing here yet, use generate button below.'
              : batch.ai_response}
          </Typography>
        </CardContent>
        <CardActions>
          <Button size="small" color="secondary" onClick={handleSaveClick}>
            Generate AI response
          </Button>
        </CardActions>
      </Card>
    </>
  )
}
