import React, { Component } from 'react'

//utils
import axios from 'axios'

import { Link as LinkRouter } from 'react-router-dom'

//material ui
import { Button, Grid, Tabs, Tab, Typography } from '@mui/material'

//components
import Layout from '../components/Layout'

import { Protocol, STATES, STATE_DEFAULT } from '../components/Protocol'

export default class Protocols extends Component {
  state = {
    batches: null,
    state: STATE_DEFAULT,
  }

  componentDidMount() {
    axios
      .get('/batches')
      .then((response) => {
        this.setState({ batches: response.data, state: STATE_DEFAULT })
      })
      .catch((error) => console.log(error.response.data))
  }

  handleChange = (event, newValue) => {
    this.setState({
      batches: this.state.batches,
      state: newValue,
    })
  }

  render() {
    return (
      <Layout title="Sample sets">
        <Grid container style={{ margin: '40px auto 40px auto' }}>
          <Grid item xs={2} />
          <Grid item xs={8}>
            <Tabs
              value={this.state.state}
              textColor="primary"
              onChange={this.handleChange}
            >
              {STATES.map((state) => (
                <Tab label={state} key={state} />
              ))}
            </Tabs>

            {this.state.batches ? (
              this.state.batches.map((batch) => (
                <Protocol
                  batch={batch}
                  key={batch.id}
                  state={this.state.state}
                />
              ))
            ) : (
              <Typography variant="body1" color="textPrimary" align="center">
                Loading...
              </Typography>
            )}
          </Grid>
          <Grid item xs={2} />
          <Grid item xs={2} />
          <Grid item xs={8} style={{ marginTop: '40px', marginBottom: '20px' }}>
            <Button
              variant="contained"
              color="secondary"
              size="medium"
              component={LinkRouter}
              to={'/protocols/newbatch'}
            >
              Generate new batch
            </Button>
          </Grid>
          <Grid item xs={2} />
        </Grid>
      </Layout>
    )
  }
}
