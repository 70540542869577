//react
import React from 'react'
import { useNavigate } from 'react-router'

//material-ui
import { Box, Button, Divider, Typography } from '@mui/material'

import { ReactComponent as SummaIcon } from '../utils/Summa-logo-white.svg'

import { Link as LinkRouter } from 'react-router-dom'

export default function Header(props) {
  const navigate = useNavigate()
  const authenticated = props.authenticated
  const title = props.title

  return (
    <>
      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          margin: '20px 20px 20px 20px',
        }}
      >
        <SummaIcon style={{ height: '40' }} />
        <Typography
          variant="body2"
          color="textPrimary"
          style={{ align: 'left', marginLeft: 20 }}
        >
          Training for hormones
        </Typography>
        {authenticated && (
          <Typography
            variant="h6"
            color="textPrimary"
            align="center"
            style={{ flexGrow: 1 }}
          >
            {title ? title : ''}
          </Typography>
        )}
        {authenticated && title && (
          <>
            <Button onClick={() => navigate(-1)} color="primary">
              Back
            </Button>
            <Button component={LinkRouter} to="/" color="primary">
              Dashboard
            </Button>
          </>
        )}
        {authenticated && !title && (
          <Button component={LinkRouter} to="/logout" color="primary">
            Logout
          </Button>
        )}
      </Box>
      <Divider />
    </>
  )
}
