import React from 'react'

//material ui
import { Card, CardContent, Slider, TextField, Typography } from '@mui/material'

export default function SampleA(props) {
  const handleChange = (event) => {
    props.setQuestionnaire({
      ...props.questionnaire,
      [event.target.name]: event.target.value,
    })
  }

  return (
    <>
      <Card style={{ margin: '20px 20px 20px 10px' }}>
        <CardContent>
          <Typography
            variant="body1"
            display="inline"
            style={{ flex: 1, textAlign: 'left', marginRight: 10 }}
          >
            On a scale of 1-10, what was the perceived exertion level of your
            exercise??
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            style={{ flex: 1, textAlign: 'left', marginRight: 10 }}
          >
            (1= no exertion at all, 10= maximal exertion)
          </Typography>
          <Slider
            name="exertionLevel"
            value={props.questionnaire.exertionLevel}
            onChange={handleChange}
            step={1}
            marks
            min={1}
            max={10}
            valueLabelDisplay="on"
            style={{ felx: 1, marginTop: 50 }}
          />
        </CardContent>
      </Card>
      <Card style={{ margin: '20px 20px 20px 10px' }}>
        <CardContent>
          <Typography
            variant="body1"
            style={{ flex: 1, textAlign: 'left', marginRight: 10 }}
          >
            Additional notes
          </Typography>
          <>
            <TextField
              name="notes"
              rows={5}
              multiline
              fullWidth
              value={props.questionnaire.notes}
              onChange={handleChange}
              style={{ flex: 1, textAlign: 'center', marginTop: 10 }}
            />
          </>
        </CardContent>
      </Card>
    </>
  )
}
