import React from 'react'

//material ui
import { Card, CardContent, TextField, Slider, Typography } from '@mui/material'

export default function SampleW(props) {
  const handleChange = (event) => {
    props.setQuestionnaire({
      ...props.questionnaire,
      [event.target.name]: event.target.value,
    })
  }

  return (
    <>
      <Card style={{ margin: '20px 20px 20px 10px' }}>
        <CardContent>
          <Typography
            variant="body1"
            display="inline"
            style={{ flex: 1, textAlign: 'left', marginRight: 10 }}
          >
            On a scale of 1-10, how would you rate the overall quality of last
            night?
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            style={{ flex: 1, textAlign: 'left', marginRight: 10 }}
          >
            (1= very poor, 10= excellent)
          </Typography>

          <Slider
            name="nightQuality"
            value={props.questionnaire.nightQuality}
            onChange={handleChange}
            step={1}
            marks
            min={1}
            max={10}
            valueLabelDisplay="on"
            style={{ felx: 1, marginTop: 50 }}
          />
        </CardContent>
      </Card>
      <Card style={{ margin: '20px 20px 20px 10px' }}>
        <CardContent>
          <Typography
            variant="body1"
            style={{ flex: 1, textAlign: 'left', marginRight: 10 }}
          >
            What time did you wake up today?
          </Typography>
          <>
            <TextField
              name="wakeupTime"
              type="time"
              value={props.questionnaire.wakeupTime}
              onChange={handleChange}
              style={{ flex: 1, textAlign: 'center', marginTop: 10 }}
              inputProps={{
                step: 300, // 5 min
              }}
            />
          </>
        </CardContent>
      </Card>
      <Card style={{ margin: '20px 20px 20px 10px' }}>
        <CardContent>
          <Typography
            variant="body1"
            style={{ flex: 1, textAlign: 'left', marginRight: 10 }}
          >
            Additional notes
          </Typography>
          <>
            <TextField
              name="notes"
              rows={5}
              multiline
              fullWidth
              value={props.questionnaire.notes}
              onChange={handleChange}
              style={{ flex: 1, textAlign: 'center', marginTop: 10 }}
            />
          </>
        </CardContent>
      </Card>
    </>
  )
}
