import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'

//components
import Header from '../components/Header'
import Footer from '../components/Footer'

//material ui
import {
  Button,
  Card,
  CardContent,
  Grid,
  FormControl,
  InputLabel,
  TextField,
  MenuItem,
  Select,
  Slider,
  Typography,
} from '@mui/material'

import axios from 'axios'

export default function SampleFirst() {
  let params = useParams()

  const [questionnaire, setQuestionnaire] = useState({
    age: 25,
    weight: 70,
    sex: 'M',
    height: 180,
    mentalStress_4w: 5,
    physicalStress_4w: 5,
    mentalStress_1w: 5,
    physicalStress_1w: 5,
    wakeupTime: '07:30',
    timezoneChange: 'No',
    restingHR: 0,
    maximumHR: 0,
  })

  const [savingStatus, setSavingStatus] = useState('loading')

  useEffect(() => {
    if (params.batch_id !== undefined) {
      axios
        .get('/samplefirst/' + params.batch_id)
        .then((response) => {
          if (response.data.status === 0) {
            setSavingStatus('ready')
          } else {
            setSavingStatus('notValid')
          }
        })
        .catch((error) => console.log(error))
    }
  }, [params.batch_id])

  const handleChange = (event) => {
    let property_value = event.target.value
    let value = 0

    switch (event.target.name) {
      case 'age':
        value = parseInt(property_value, 10)

        if (value > 120) value = 120
        if (value < 0) value = 0

        setQuestionnaire({
          ...questionnaire,
          age: value,
        })
        break

      case 'weight':
      case 'height':
      case 'restingHR':
      case 'maximumHR':
        value = isNaN(property_value) ? 0 : parseInt(property_value, 10)

        if (value > 220) value = 220
        if (value < 0) value = 0

        setQuestionnaire({
          ...questionnaire,
          [event.target.name]: value,
        })
        break

      default:
        setQuestionnaire({
          ...questionnaire,
          [event.target.name]: property_value,
        })
    }
  }

  const handleSaveClick = () => {
    if (savingStatus !== 'ready') return

    let body = {
      questionnaire: questionnaire,
    }

    setSavingStatus('saving')

    axios
      .post('/batch/questionnaire/' + params.batch_id, body)
      .then(() => {
        setSavingStatus('saved_success')
      })
      .catch(() => {
        setSavingStatus('saved_error')
      })
  }

  return (
    <div>
      <Header />

      {savingStatus === 'ready' && (
        <Card style={{ margin: '20px 20px 20px 10px' }}>
          <CardContent>
            <Typography
              variant="body1"
              style={{ flex: 1, textAlign: 'left', marginRight: 10 }}
            >
              Age
            </Typography>

            <>
              <TextField
                name="age"
                type="number"
                value={questionnaire.age}
                style={{
                  flex: 1,
                  textAlign: 'center',
                  marginTop: 10,
                  width: 100,
                }}
                onChange={handleChange}
                inputProps={{
                  max: 120,
                  min: 0,
                  pattern: '[0-9]*',
                }}
              />
            </>
          </CardContent>
        </Card>
      )}

      {savingStatus === 'ready' && (
        <Card style={{ margin: '20px 20px 20px 10px' }}>
          <CardContent>
            <Typography
              variant="body1"
              style={{ flex: 1, textAlign: 'left', marginRight: 10 }}
            >
              Weight (kg)
            </Typography>
            <>
              <TextField
                name="weight"
                type="number"
                value={questionnaire.weight}
                style={{
                  flex: 1,
                  textAlign: 'center',
                  marginTop: 10,
                  width: 100,
                }}
                onChange={handleChange}
                inputProps={{
                  max: 200,
                  min: 0,
                  pattern: '[0-9]*',
                }}
              />
            </>
          </CardContent>
        </Card>
      )}

      {savingStatus === 'ready' && (
        <Card style={{ margin: '20px 20px 20px 10px' }}>
          <CardContent>
            <FormControl fullWidth variant="standard">
              <InputLabel id="sex-label">Sex</InputLabel>
              <Select
                name="sex"
                labelId="sex-label"
                id="sex-select"
                value={questionnaire.sex}
                onChange={handleChange}
              >
                <MenuItem value={'M'}>Male</MenuItem>
                <MenuItem value={'F'}>Female</MenuItem>
              </Select>
            </FormControl>
          </CardContent>
        </Card>
      )}

      {savingStatus === 'ready' && (
        <Card style={{ margin: '20px 20px 20px 10px' }}>
          <CardContent>
            <Typography
              variant="body1"
              style={{ flex: 1, textAlign: 'left', marginRight: 10 }}
            >
              Height (cm)
            </Typography>
            <>
              <TextField
                name="height"
                type="number"
                value={questionnaire.height}
                style={{
                  flex: 1,
                  textAlign: 'center',
                  marginTop: 10,
                  width: 100,
                }}
                onChange={handleChange}
                inputProps={{
                  max: 210,
                  min: 0,
                  pattern: '[0-9]*',
                }}
              />
            </>
          </CardContent>
        </Card>
      )}

      {savingStatus === 'ready' && (
        <Card style={{ margin: '20px 20px 20px 10px' }}>
          <CardContent>
            <Typography
              variant="body1"
              display="inline"
              style={{ flex: 1, textAlign: 'left', marginRight: 10 }}
            >
              On a scale of 1-10, how would you rate your mental stress over the
              past 4 weeks?
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              style={{ flex: 1, textAlign: 'left', marginRight: 10 }}
            >
              (1= minimal stress, 10 = clearly exceeding my normal stress
              levels)
            </Typography>
            <Slider
              name="mentalStress_4w"
              value={questionnaire.mentalStress_4w}
              onChange={handleChange}
              step={1}
              marks
              min={1}
              max={10}
              valueLabelDisplay="on"
              style={{ felx: 1, marginTop: 50 }}
            />
          </CardContent>
        </Card>
      )}

      {savingStatus === 'ready' && (
        <Card style={{ margin: '20px 20px 20px 10px' }}>
          <CardContent>
            <Typography
              variant="body1"
              display="inline"
              style={{ flex: 1, textAlign: 'left', marginRight: 10 }}
            >
              On a scale of 1-10, how would you rate your physical stress over
              the past 4 weeks?
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              style={{ flex: 1, textAlign: 'left', marginRight: 10 }}
            >
              (1= minimal stress, 10 = clearly exceeding my normal stress
              levels)
            </Typography>
            <Slider
              name="physicalStress_4w"
              value={questionnaire.physicalStress_4w}
              onChange={handleChange}
              step={1}
              marks
              min={1}
              max={10}
              valueLabelDisplay="on"
              style={{ felx: 1, marginTop: 50 }}
            />
          </CardContent>
        </Card>
      )}

      {savingStatus === 'ready' && (
        <Card style={{ margin: '20px 20px 20px 10px' }}>
          <CardContent>
            <Typography
              variant="body1"
              display="inline"
              style={{ flex: 1, textAlign: 'left', marginRight: 10 }}
            >
              On a scale of 1-10, how would you rate your mental stress over the
              past week?
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              style={{ flex: 1, textAlign: 'left', marginRight: 10 }}
            >
              (1= minimal stress, 10 = clearly exceeding my normal stress
              levels)
            </Typography>
            <Slider
              name="mentalStress_1w"
              value={questionnaire.mentalStress_1w}
              onChange={handleChange}
              step={1}
              marks
              min={1}
              max={10}
              valueLabelDisplay="on"
              style={{ felx: 1, marginTop: 50 }}
            />
          </CardContent>
        </Card>
      )}

      {savingStatus === 'ready' && (
        <Card style={{ margin: '20px 20px 20px 10px' }}>
          <CardContent>
            <Typography
              variant="body1"
              display="inline"
              style={{ flex: 1, textAlign: 'left', marginRight: 10 }}
            >
              On a scale of 1-10, how would you rate your physical stress over
              the past week?
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              style={{ flex: 1, textAlign: 'left', marginRight: 10 }}
            >
              (1= minimal stress, 10 = clearly exceeding my normal stress
              levels)
            </Typography>
            <Slider
              name="physicalStress_1w"
              value={questionnaire.physicalStress_1w}
              onChange={handleChange}
              step={1}
              marks
              min={1}
              max={10}
              valueLabelDisplay="on"
              style={{ felx: 1, marginTop: 50 }}
            />
          </CardContent>
        </Card>
      )}

      {savingStatus === 'ready' && (
        <Card style={{ margin: '20px 20px 20px 10px' }}>
          <CardContent>
            <Typography
              variant="body1"
              style={{ flex: 1, textAlign: 'left', marginRight: 10 }}
            >
              Over the past four weeks, what is your typical wake-up time?
            </Typography>
            <>
              <TextField
                name="wakeupTime"
                type="time"
                value={questionnaire.wakeupTime}
                onChange={handleChange}
                style={{ flex: 1, textAlign: 'center', marginTop: 10 }}
                inputProps={{
                  step: 300, // 5 min
                }}
              />
            </>
          </CardContent>
        </Card>
      )}

      {savingStatus === 'ready' && (
        <Card style={{ margin: '20px 20px 20px 10px' }}>
          <CardContent>
            <FormControl fullWidth variant="standard">
              <InputLabel id="timezone-label">
                Have you changed time zones past 7 days for more than 24 hours?
              </InputLabel>
              <Select
                name="timezoneChange"
                labelId="timezone-label"
                id="timezone-select"
                value={questionnaire.timezoneChange}
                onChange={handleChange}
                style={{ marginTop: '40px' }}
              >
                <MenuItem value={'No'}>No</MenuItem>
                <MenuItem value={'Yes'}>Yes</MenuItem>
              </Select>
            </FormControl>
          </CardContent>
        </Card>
      )}

      {savingStatus === 'ready' && (
        <Card style={{ margin: '20px 20px 20px 10px' }}>
          <CardContent>
            <Typography
              variant="body1"
              style={{ flex: 1, textAlign: 'left', marginRight: 10 }}
            >
              What is your typical night time resting heart rate?
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              style={{ flex: 1, textAlign: 'left', marginRight: 10 }}
            >
              (bpm) Please leave 0, if not available
            </Typography>

            <>
              <TextField
                name="restingHR"
                type="number"
                value={questionnaire.restingHR}
                style={{
                  flex: 1,
                  textAlign: 'center',
                  marginTop: 10,
                  width: 100,
                }}
                onChange={handleChange}
                inputProps={{
                  max: 220,
                  min: 0,
                  pattern: '[0-9]*',
                }}
              />
            </>
          </CardContent>
        </Card>
      )}

      {savingStatus === 'ready' && (
        <Card style={{ margin: '20px 20px 20px 10px' }}>
          <CardContent>
            <Typography
              variant="body1"
              style={{ flex: 1, textAlign: 'left', marginRight: 10 }}
            >
              What is your maximum heart rate?
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              style={{ flex: 1, textAlign: 'left', marginRight: 10 }}
            >
              (bpm) Please leave 0, if not available
            </Typography>

            <>
              <TextField
                name="maximumHR"
                type="number"
                value={questionnaire.maximumHR}
                style={{
                  flex: 1,
                  textAlign: 'center',
                  marginTop: 10,
                  width: 100,
                }}
                onChange={handleChange}
                inputProps={{
                  max: 250,
                  min: 0,
                  pattern: '[0-9]*',
                }}
              />
            </>
          </CardContent>
        </Card>
      )}

      {(savingStatus === 'ready' || savingStatus === 'saving') && (
        <Grid container style={{ margin: '40px auto 40px auto' }}>
          <Grid item xs={2} />
          <Grid item xs={8}>
            <Button
              variant="contained"
              color="secondary"
              size="medium"
              fullWidth
              onClick={handleSaveClick}
            >
              {savingStatus === 'ready' && 'Save'}
              {savingStatus === 'saving' && 'Saving...'}
            </Button>
          </Grid>
          <Grid item xs={2} />
        </Grid>
      )}

      {savingStatus === 'saved_success' && (
        <>
          <Typography
            variant="h3"
            color="primary"
            align="center"
            style={{ margin: '40px 10px 20px 10px' }}
          >
            Thank you!
          </Typography>
          <Typography
            variant="subtitle2"
            color="primary"
            align="center"
            style={{ margin: '40px 10px 20px 10px' }}
          >
            Questionnaire has been saved.
          </Typography>
          <Grid container style={{ margin: '40px auto 40px auto' }}>
            <Grid item xs={4} />
            <Grid item xs={4}>
              <Button
                variant="contained"
                color="secondary"
                size="medium"
                fullWidth
              >
                <a href="https://summa.bio">Close</a>
              </Button>
            </Grid>
            <Grid item xs={4} />
          </Grid>
        </>
      )}

      {savingStatus === 'notValid' && (
        <>
          <Typography
            variant="h3"
            color="primary"
            align="center"
            style={{ margin: '40px 10px 20px 10px' }}
          >
            Error
          </Typography>
          <Typography
            variant="subtitle2"
            color="primary"
            align="center"
            style={{ margin: '40px 10px 20px 10px' }}
          >
            Questionnaire has been already saved.
          </Typography>
          <Grid container style={{ margin: '40px auto 40px auto' }}>
            <Grid item xs={4} />
            <Grid item xs={4}>
              <Button
                variant="contained"
                color="secondary"
                size="medium"
                fullWidth
              >
                <a href="https://summa.bio">Close</a>
              </Button>
            </Grid>
            <Grid item xs={4} />
          </Grid>
        </>
      )}

      <Typography
        variant="body2"
        color="textSecondary"
        align="center"
        style={{ margin: '40px 10px 20px 10px' }}
      >
        Questionnaire number: {params.batch_id}
      </Typography>

      <Footer />
    </div>
  )
}
