import React, { useContext, useState, useEffect } from 'react'
import { auth } from '../utils/firebase'

import {
  isSignInWithEmailLink,
  sendSignInLinkToEmail,
  signInWithEmailLink,
  signOut,
} from 'firebase/auth'

import axios from 'axios'

const AuthContext = React.createContext()

export function useAuth() {
  return useContext(AuthContext)
}

export function AuthProvider({ children }) {
  const [user, setUser] = useState()
  const [initializing, setInitializing] = useState(true)

  function sendLoginLink(email, returnURL) {
    const actionCodeSettings = {
      url: returnURL,
      handleCodeInApp: true,
    }
    return sendSignInLinkToEmail(auth, email, actionCodeSettings)
  }

  function validateLink(link) {
    return isSignInWithEmailLink(auth, link)
  }

  function loginWithLink(email, link) {
    return signInWithEmailLink(auth, email, link)
  }

  function logout() {
    return signOut(auth)
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user)

      if (user) {
        axios.defaults.headers.common['Authorization'] =
          'Bearer ' + user.accessToken

        axios.interceptors.response.use(
          (response) => {
            return response
          },
          async function (error) {
            const originalRequest = error.config
            if (error.response.status === 403 && !originalRequest._retry) {
              originalRequest._retry = true
              const access_token = await user.getIdToken()
              axios.defaults.headers.common['Authorization'] =
                'Bearer ' + access_token
              originalRequest.headers['Authorization'] =
                'Bearer ' + access_token
              return axios(originalRequest)
            }
            return Promise.reject(error)
          },
        )

        if (process.env.NODE_ENV === 'development')
          console.log(user.accessToken)
      }
      setInitializing(false)
    })

    return unsubscribe
  }, [])

  const value = {
    user,
    sendLoginLink,
    validateLink,
    loginWithLink,
    logout,
  }

  return (
    <AuthContext.Provider value={value}>
      {!initializing && children}
    </AuthContext.Provider>
  )
}
