import React, { useEffect, useState } from 'react'

import { useParams } from 'react-router'
import QRCode from 'react-qr-code'
import Barcode from 'react-barcode'
import axios from 'axios'

//material-ui
import { Box, Grid, Typography } from '@mui/material'

export default function ProtocolsBarcode() {
  const [samples, setSamples] = useState(null)

  let params = useParams()

  useEffect(() => {
    axios
      .get('/samples/' + params.batch_id)
      .then((response) => {
        setSamples(response.data)
      })
      .catch((error) => console.log(error))
  }, [params.batch_id])

  return samples ? (
    <div>
      <Box
        style={{
          minHeight: '60px',
          padding: '20px',
          margin: '20px 20px 20px 20px',
          background: '#fff',
          borderRadius: '0px',
        }}
      >
        <Box
          style={{
            margin: '20px 20px 20px 20px',
          }}
        >
          <Typography variant="body1" align="left">
            THIS IS BATCH BOX COVER
          </Typography>
        </Box>
        <QRCode
          value={
            process.env.REACT_APP_TARGET === 'production'
              ? process.env.REACT_APP_PROD_LOGIN_LANDING_PAGE +
                '/batch/' +
                params.batch_id
              : process.env.REACT_APP_DEV_LOGIN_LANDING_PAGE +
                '/batch/' +
                params.batch_id
          }
          size={75}
          level="M"
        />
        <Box
          style={{
            margin: '20px 20px 20px 20px',
          }}
        >
          <Typography variant="body1" align="left">
            {params.batch_id}
          </Typography>
        </Box>
      </Box>

      <Grid container>
        {samples.map((sample) => (
          <Grid key={sample.id} item>
            <Box
              style={{
                height: '100px',
                width: '250px',
                padding: '20px',
                margin: '20px 20px 20px 20px',
                background: '#fff',
                borderRadius: '0px',
              }}
            >
              <Barcode
                value={sample.short_id}
                height={50}
                displayValue={false}
              />
              <Box>
                <Typography variant="caption">
                  {sample.short_id} {sample.id}
                </Typography>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </div>
  ) : (
    <Typography
      variant="body1"
      color="textPrimary"
      align="center"
      style={{ marginTop: '40px' }}
    >
      Preparing page...
    </Typography>
  )
}
