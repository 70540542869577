import React from 'react'

import Header from './Header'
import Footer from './Footer'

//auth
import { useAuth } from '../contexts/AuthContext'

const Layout = ({ title, children }) => {
  const auth = useAuth()

  return (
    <>
      <Header authenticated={auth.user !== null} title={title} />
      {children}
      <Footer />
    </>
  )
}

export default Layout
