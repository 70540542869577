import { React, useState } from 'react'

//material ui
import {
  Button,
  Divider,
  FormControlLabel,
  TextField,
  Typography,
  FormControl,
  RadioGroup,
  Radio,
} from '@mui/material'

//ng/ml
const controlsCor = {
  c1_exact: 0.35,
  c1_min: 0.2,
  c1_max: 0.6,
  c2_exact: 2.11,
  c2_min: 1.4,
  c2_max: 2.9,
}

//pg/ml
const controlsTes = {
  c1_exact: 58,
  c1_min: 34,
  c1_max: 70,
  c2_exact: 202,
  c2_min: 131,
  c2_max: 272,
}

//ng/ml
const standardsCor = {
  a1b1: 0,
  c1b1: 0.1,
  e1f1: 0.4,
  g1h1: 1.7,
  a2b2: 7,
  c2d2: 30,
}

//pg/ml
const standardsTes = {
  a1b1: 0,
  c1b1: 10,
  e1f1: 30,
  g1h1: 100,
  a2b2: 300,
  c2d2: 1000,
}

//pg/ml
const standardsTes_2 = {
  a1b1: 0,
  c1b1: 10,
  e1f1: 30,
  g1h1: 100,
  a2b2: 300,
  c2d2: 900,
}

export const TEST_TYPE_COR = 0
export const TEST_TYPE_TES = 1
export const TEST_TYPE_TES_2 = 2

export const defaultStandards = [standardsCor, standardsTes, standardsTes_2]
export const defualtControls = [controlsCor, controlsTes, controlsTes]

export function LabNewTestS3({ testSet, onNext }) {
  const [testType, setTestType] = useState(testSet.type)
  const [controls, setControls] = useState({ ...testSet.controls })
  const [standards, setStandards] = useState({ ...testSet.standards })

  const handleChange = (event) => {
    setStandards({
      ...standards,
      [event.target.name]: parseFloat(event.target.value),
    })
  }

  return (
    <>
      <Divider />
      <FormControl style={{ margin: '20px auto 20px 50px' }}>
        <RadioGroup
          row
          name="test"
          value={testType}
          onChange={(event) => {
            let type = parseInt(event.target.value, 10)
            setTestType(type)
            setControls({ ...defualtControls[type] })
            setStandards({ ...defaultStandards[type] })
          }}
        >
          <FormControlLabel
            style={{ minWidth: 200 }}
            value={TEST_TYPE_COR}
            control={<Radio />}
            label="Cortisol [ng/mL]"
          />
          <FormControlLabel
            style={{ minWidth: 200 }}
            value={TEST_TYPE_TES}
            control={<Radio />}
            label="DEMEDITEC Testosteron [pg/mL]"
          />
          <FormControlLabel
            style={{ minWidth: 200 }}
            value={TEST_TYPE_TES_2}
            control={<Radio />}
            label="TECAN Testosteron [pg/mL]"
          />
        </RadioGroup>
      </FormControl>
      <Typography style={{ margin: '20px auto 20px 50px' }}>
        Standards
      </Typography>
      <TextField
        label="A1/B1"
        type="number"
        name="a1b1"
        value={standards.a1b1}
        style={{ width: 85, margin: '20px auto 20px 50px' }}
        onChange={handleChange}
        inputProps={{
          pattern: '[0-9]*',
        }}
      />
      <TextField
        label="C1/D1"
        type="number"
        name="c1b1"
        value={standards.c1b1}
        style={{ width: 85, margin: '20px auto 20px 50px' }}
        onChange={handleChange}
        inputProps={{
          pattern: '[0-9]*',
        }}
      />
      <TextField
        label="E1/F1"
        type="number"
        name="e1f1"
        value={standards.e1f1}
        style={{ width: 85, margin: '20px auto 20px 50px' }}
        onChange={handleChange}
        inputProps={{
          pattern: '[0-9]*',
        }}
      />
      <TextField
        label="G1/H1"
        type="number"
        name="g1h1"
        value={standards.g1h1}
        style={{ width: 85, margin: '20px auto 20px 50px' }}
        onChange={handleChange}
        inputProps={{
          pattern: '[0-9]*',
        }}
      />
      <TextField
        label="A2/B2"
        type="number"
        name="a2b2"
        value={standards.a2b2}
        style={{ width: 85, margin: '20px auto 20px 50px' }}
        onChange={handleChange}
        inputProps={{
          pattern: '[0-9]*',
        }}
      />
      <TextField
        label="C2/D2"
        type="number"
        name="c2d2"
        value={standards.c2d2}
        style={{ width: 85, margin: '20px auto 20px 50px' }}
        onChange={handleChange}
        inputProps={{
          pattern: '[0-9]*',
        }}
      />
      <Typography style={{ margin: '20px auto 20px 50px' }}>
        Controls 1
      </Typography>
      <TextField
        label="E2/F2 - C-Exact"
        type="number"
        value={controls.c1_exact}
        style={{ width: 150, margin: '20px auto 20px 50px' }}
        onChange={(event) => {
          setControls({
            ...controls,
            c1_exact: parseFloat(event.target.value),
          })
        }}
        inputProps={{
          pattern: '[0-9]*',
        }}
      />
      <TextField
        label="E2/F2 - C-Min"
        type="number"
        value={controls.c1_min}
        style={{ width: 150, margin: '20px auto 20px 50px' }}
        onChange={(event) => {
          setControls({
            ...controls,
            c1_min: parseFloat(event.target.value),
          })
        }}
        inputProps={{
          pattern: '[0-9]*',
        }}
      />
      <TextField
        label="E2/F2 - C-Max"
        type="number"
        value={controls.c1_max}
        style={{ width: 150, margin: '20px auto 20px 50px' }}
        onChange={(event) => {
          setControls({
            ...controls,
            c1_max: parseFloat(event.target.value),
          })
        }}
        inputProps={{
          pattern: '[0-9]*',
        }}
      />
      <Typography style={{ margin: '20px auto 20px 50px' }}>
        Controls 2
      </Typography>
      <TextField
        label="G2/H2 - C-Exact"
        type="number"
        value={controls.c2_exact}
        style={{ width: 150, margin: '20px auto 20px 50px' }}
        onChange={(event) => {
          setControls({
            ...controls,
            c2_exact: parseFloat(event.target.value),
          })
        }}
        inputProps={{
          pattern: '[0-9]*',
        }}
      />
      <TextField
        label="G2/H2 - C-Min"
        type="number"
        value={controls.c2_min}
        style={{ width: 150, margin: '20px auto 20px 50px' }}
        onChange={(event) => {
          setControls({
            ...controls,
            c2_min: parseFloat(event.target.value),
          })
        }}
        inputProps={{
          pattern: '[0-9]*',
        }}
      />
      <TextField
        label="G2/H2 - C-Max"
        type="number"
        value={controls.c2_max}
        style={{ width: 150, margin: '20px auto 20px 50px' }}
        onChange={(event) => {
          setControls({
            ...controls,
            c2_max: parseFloat(event.target.value),
          })
        }}
        inputProps={{
          pattern: '[0-9]*',
        }}
      />
      <Divider />
      <Button
        variant="contained"
        color="secondary"
        size="small"
        onClick={() => {
          onNext(testType, standards, controls)
        }}
        style={{ margin: '20px auto 20px 40px' }}
      >
        Next
      </Button>
    </>
  )
}
